import { scrollToMessage } from "@domain/helpers/message-helper";
import { Chat } from "@domain/interfaces/conversation-interface";
import { defineStore } from "pinia";
import { useConversationStore } from "./conversation-store";
import { useSearchStore } from "./search-store";

type MiddleColumnSlot = "chat" | "pinMessage" | "selectContact" | "createGroup" | "mainFolder" | "createFolder" | "editFolder";
type ConversationAction = "forward" | "createFolder" | "editFolder";

export const useAppStateStore = defineStore("app-state", {
  state: () => ({
    middleColumnSlot: undefined as MiddleColumnSlot | undefined,
    userConversationModal: {
      visible: false,
      action: undefined as ConversationAction | undefined,
      selectedList: [] as Chat[],
      fetching: false,
    },
  }),
  actions: {
    setMiddleColumnSlot (slot: MiddleColumnSlot) {
      this.middleColumnSlot = slot;
    },
    resetMiddleColumn () {
      this.middleColumnSlot = undefined;
    },
    closePinMessage () {
      this.middleColumnSlot = "chat";
      scrollToMessage(useConversationStore().selectedConversation.conversationDetails?.lastSeenMessageId);
    },
    openUserConversationModal (action: ConversationAction) {
      this.userConversationModal.action = action;
      this.userConversationModal.visible = true;
      this.userConversationModal.fetching = false;

      useSearchStore().conversation.items = useConversationStore().data.conversations.items.map((item) => ({
        ...item,
        type: "chat",
        username: "",
      }));
    },
    closeChatUserModal () {
      this.userConversationModal.action = undefined;
      this.userConversationModal.visible = false;
    },
  },
});
