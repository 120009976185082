/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { IAccountLoginBody, IAccountLoginResponse } from "@domain/interfaces/account-interface";
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { Socket } from "socket.io-client";

export default abstract class AccountRepository {
  login (data: IAccountLoginBody, sign: string): Promise<IAccountLoginResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  connectAccountSocket (token: string): void {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  connectLoginSocket (): Socket<ServerToClientEvents, ClientToServerEvents> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
