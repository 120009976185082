import Conversation from "@domain/entities/conversation-entity";

import addConversationMemberUseCase from "@domain/use-cases/conversation/add-members";
import createConversationUseCase from "@domain/use-cases/conversation/create-conversation";
import getConversationDetailUseCase from "@domain/use-cases/conversation/get-conversation-detail";
import getConversationListUseCase from "@domain/use-cases/conversation/get-conversation-list";
import getConversationMemberUseCase from "@domain/use-cases/conversation/get-conversation-member";
import getConversationFileListUseCase from "@domain/use-cases/conversation/get-file-list";
import getConversationMediaListUseCase from "@domain/use-cases/conversation/get-media-list";
import getPinConversationListUseCase from "@domain/use-cases/conversation/get-pin-conversation-list";
import getSaveMessageConversationUseCase from "@domain/use-cases/conversation/get-save-message-conversation";
import leaveConversationUseCase from "@domain/use-cases/conversation/leave-conversation";
import removeConversationUseCase from "@domain/use-cases/conversation/remove-conversation";
import removeMemberUseCase from "@domain/use-cases/conversation/remove-member";
import updateConversationNameUseCase from "@domain/use-cases/conversation/update-conversation-name";
import updateConversationDetailsUseCase from "@domain/use-cases/conversation/update-details";
import getFolderConversationListUseCase from "@domain/use-cases/folder/server/get-folder-conversation";
import uploadMediaUseCase from "@domain/use-cases/media/upload-media";
import searchMessageWithinConversationUseCase from "@domain/use-cases/message/search-conversation-message";

import { scrollToEndConversation } from "@domain/helpers/message-helper";
import { UpdateConversationDetailsBody } from "@domain/interfaces/conversation-details-interface";
import {
  ConversationMedia,
  CreateConversationBody,
  GetConversationDetailResponse,
  GetUserConversationQuery,
  IConversationMember,
} from "@domain/interfaces/conversation-interface";
import { IMessage, SearchMessageItem, SearchMessageWithinConversationQuery } from "@domain/interfaces/message-interface";
import { IUser } from "@domain/interfaces/user-interface";
import { SelectProps } from "ant-design-vue";
import { defineStore } from "pinia";
import { useAccountStore } from "./account-store";
import { useAlertStore } from "./alert-store";
import { useAppStateStore } from "./app-store";
import { useAudioStateStore } from "./audio-store";
import { useChatDialogStateStore } from "./chat-dialog-store";
import { useMessageStore } from "./message-store";

export const useConversationStore = defineStore("conversation", {
  state: () => ({
    activeFolderId: "",
    data: {
      conversations: {
        items: [] as Conversation[],
        total: 0,
        limit: 10,
        fetching: false,
        isMore: true,
        moreFetching: false,
      },
      folderConversationList: [] as Conversation[],
    },
    activeConversationMedia: {
      items: [] as ConversationMedia[],
      isCall: false,
    },
    activeConversationFiles: {
      items: [] as ConversationMedia[],
      isCall: false,
    },
    activeConversationMember: {
      items: [] as IConversationMember[],
      isMore: false,
      fetching: false,
      page: 0,
    },
    searchQuery: <GetUserConversationQuery>{},
    selectedConversation: {} as Conversation,
    alerts: [] as string[],
  }),
  getters: {
    conversationList: (state) => {
      return state.activeFolderId ? state.data.folderConversationList : state.data.conversations.items;
    },
    selectedConversationMentionList: (state) => {
      if (!state.selectedConversation.id || !state.selectedConversation.isGroup) return [];

      return state.selectedConversation.membersInfo
        .filter((item) => item.username !== useAccountStore().loginUser.username)
        .map((item) => ({
          label: item.name,
          value: item.username,
          avatar: item.avatar,
        }));
    },

    selectedConversationMemberOptions: (state) => {
      if (!state.selectedConversation.id || !state.selectedConversation.isGroup) return [];

      return state.selectedConversation.membersInfo.map((item) => ({
        label: item.name,
        value: item.username,
        avatar: item.avatar,
      })) as SelectProps["options"];
    },
    selectedConversationIndex: (state) => {
      return state.data.conversations.items.findIndex((item) => item.id === state.selectedConversation.id);
    },
    unPinFirstIndex: (state) => {
      return state.data.conversations.items.findIndex((item) => !item.conversationDetails.isPin);
    },
  },
  actions: {
    async setActiveFolderId (id: string) {
      this.activeFolderId = id;

      if (id) {
        const result = await getFolderConversationListUseCase({ id });
        console.log(result.data.conversations.docs);
        this.data.folderConversationList = result.data.conversations.docs;
      }
    },
    async getConversationList () {
      this.data.conversations.fetching = true;
      this.data.conversations.items = [];

      const pinListResult = await getPinConversationListUseCase();
      const result = await getConversationListUseCase({ type: "internal" });

      if (result.code !== 1) {
        useAlertStore().error(result.message);
        this.data.conversations.fetching = false;

        return;
      }

      this.data.conversations = {
        items: pinListResult.data.items.concat(result.data.items),
        total: result.data.total,
        limit: result.data.limit,
        fetching: false,
        moreFetching: false,
        isMore: result.data.items.length === result.data.limit,
      };
    },
    async getMoreConversation () {
      if (this.data.conversations.isMore) {
        this.data.conversations.moreFetching = true;
        const result = await getConversationListUseCase({
          type: "internal",
          updatedAt: this.data.conversations.items[this.data.conversations.items.length - 1].updatedAt,
        });

        if (result.code !== 1) {
          useAlertStore().error(result.message);
          this.data.conversations.moreFetching = false;

          return;
        }

        this.data.conversations.moreFetching = false;
        this.data.conversations.isMore = result.data.items.length === result.data.limit;
        this.data.conversations.items = this.data.conversations.items.concat(result.data.items);
      }
    },
    async getConversationDetail (query: { id?: string; username?: string; messageCreatedAt?: Date }, force = false) {
      let result: GetConversationDetailResponse = {
        data: {} as Conversation,
        status: 0,
        code: 0,
        message: "",
      };

      if (force) {
        result = await getConversationDetailUseCase(query);
      } else if (query.id && this.selectedConversation?.id && query.id === this.selectedConversation.id && !query.messageCreatedAt) {
        return;
      } else if (query.username && !this.selectedConversation.members.includes(query.username)) {
        return;
      } else {
        result = await getConversationDetailUseCase(query);
      }

      if (result.code !== 1) {
        useAlertStore().error(result.message);

        return;
      }

      this.setSelectConversation(result.data, query.messageCreatedAt);
    },
    async createConversation (value: CreateConversationBody, avatarFile?: File) {
      let avatar = "";

      if (avatarFile) {
        const uploadResult = await uploadMediaUseCase(avatarFile);

        if (uploadResult.code === 1 && uploadResult.data.link) {
          avatar = uploadResult.data.link;
        }
      }
      const result = await createConversationUseCase({
        members: [useAccountStore().loginUser.username].concat(value.members),
        isGroup: value.isGroup,
        name: value.name,
        avatar,
      });

      if (result.code !== 1) {
        return useAlertStore().error(result.message);
      }

      this.data.conversations.items.unshift(result.data.conversation);
      this.setSelectConversation(result.data.conversation);
    },
    async getSaveMessageConversation () {
      const result = await getSaveMessageConversationUseCase();

      if (result.code === 1) {
        this.setSelectConversation(result.data.conversation);
      }
    },
    async getConversationMember () {
      this.activeConversationMember.fetching = true;
      this.activeConversationMember.page = 1;

      const result = await getConversationMemberUseCase({
        conversationId: this.selectedConversation.id,
        page: this.activeConversationMember.page,
      });

      this.activeConversationMember.fetching = false;

      if (result.code !== 1) {
        return;
      }

      this.activeConversationMember.items = result.data.items;
      this.activeConversationMember.isMore = result.data.items.length === result.data.limit;
    },
    async getMoreConversationMember () {
      this.activeConversationMember.fetching = true;
      this.activeConversationMember.page = this.activeConversationMember.page + 1;

      const result = await getConversationMemberUseCase({
        conversationId: this.selectedConversation.id,
        page: this.activeConversationMember.page,
      });

      this.activeConversationMember.fetching = false;

      if (result.code !== 1) {
        return;
      }

      this.activeConversationMember.items = this.activeConversationMember.items.concat(result.data.items);
      this.activeConversationMember.isMore = result.data.items.length === result.data.limit;
    },
    async getConversationMedia () {
      if (this.activeConversationMedia.isCall) {
        return;
      }

      const mediaResult = await getConversationMediaListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtAfter: this.activeConversationMedia.items.length > 0 ? this.activeConversationMedia.items[0].createdAt : undefined,
      });

      if (mediaResult.code === 1) {
        this.activeConversationMedia = {
          items: mediaResult.data.items,
          isCall: true,
        };
      }
    },
    async getConversationFile () {
      if (this.activeConversationFiles.isCall) {
        return;
      }

      const fileResult = await getConversationFileListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtAfter: this.activeConversationFiles.items.length > 0 ? this.activeConversationFiles.items[0].createdAt : undefined,
      });

      if (fileResult.code === 1) {
        this.activeConversationFiles = { items: fileResult.data.items, isCall: true };
      }
    },
    updateConversationDetails (data: UpdateConversationDetailsBody) {
      // console.log(new Date(data.lastSeenAt).toLocaleString(), new Date(this.selectedConversation.conversationDetails.lastSeenAt).toLocaleString());
      // console.log(data);

      if (!data.lastSeenMessageId || !data.lastSeenAt) {
        return;
      }

      if (!this.selectedConversation.conversationDetails.lastSeenAt || data.lastSeenAt >= this.selectedConversation.conversationDetails.lastSeenAt) {
        this.selectedConversation.conversationDetails.lastSeenAt = data.lastSeenAt;
        this.selectedConversation.conversationDetails.lastSeenMessageId = data.lastSeenMessageId;
        updateConversationDetailsUseCase(data);
      }
    },

    async removeMember (member: IUser) {
      const result = await removeMemberUseCase({ id: this.selectedConversation.id, members: [member.username] });

      if (result.code !== 1) return;
      this.activeConversationMember.items = this.activeConversationMember.items.filter((item) => item.username !== member.username);
      this.alerts = this.alerts.concat(result.data.alerts);

      scrollToEndConversation();
    },
    async renameGroup () {
      const result = await updateConversationNameUseCase({ id: this.selectedConversation.id, name: this.selectedConversation.name });

      if (result.code !== 1) return;

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async addMember (members: IUser[]) {
      const result = await addConversationMemberUseCase({ id: this.selectedConversation.id, members: members.map((item) => item.username) });

      if (result.code !== 1) return;

      this.activeConversationMember.items = this.activeConversationMember.items.concat(
        members.map((item) => ({ ...item, role: "member", roleText: "Member" }))
      );

      useChatDialogStateStore().closeContactModal();

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async leaveGroup () {
      const result = await leaveConversationUseCase({ id: this.selectedConversation.id });

      if (result.code !== 1) return;

      this.selectedConversation = {} as Conversation;

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async searchMessageWithinConversation (query: SearchMessageWithinConversationQuery) {
      const result = await searchMessageWithinConversationUseCase(query);

      if (result.code !== 1) return [] as SearchMessageItem[];

      return result.data.items;
    },
    async muteNotification (conversationId: string, isMute: boolean) {
      updateConversationDetailsUseCase({
        isMute,
        conversationId,
      });
      const index = this.data.conversations.items.findIndex((item) => item.id === conversationId);

      if (index >= 0) {
        if (this.selectedConversationIndex === index) {
          this.selectedConversation.conversationDetails.isMute = isMute;
        }
        this.data.conversations.items[index].conversationDetails.isMute = isMute;
      }
    },
    async pinConversation (conversationId: string, isPin: boolean) {
      updateConversationDetailsUseCase({ conversationId, isPin });
      const index = this.data.conversations.items.findIndex((item) => item.id === conversationId);

      if (index >= 0) {
        this.data.conversations.items[index].conversationDetails.isPin = isPin;

        if (isPin) {
          this.data.conversations.items.splice(this.unPinFirstIndex, 0, this.data.conversations.items[index]);
        }
      }
    },
    async removeConversation (conversationId: string) {
      removeConversationUseCase(conversationId);

      if (this.selectedConversation.id === conversationId) {
        this.selectedConversation = {} as Conversation;
      }

      this.data.conversations.items = this.data.conversations.items.filter((item) => item.id !== conversationId);
    },
    // local

    setSelectConversation (conversation: Conversation, messageCreatedAt?: Date) {
      // console.log(id);
      const messageStore = useMessageStore();
      const chatDialogStateStore = useChatDialogStateStore();
      const audioStore = useAudioStateStore();
      const appStateStore = useAppStateStore();

      this.selectedConversation = conversation;
      this.activeConversationMedia = { items: [], isCall: false };
      this.activeConversationFiles = { items: [], isCall: false };
      this.activeConversationMember = { items: [], page: 1, fetching: false, isMore: false };

      chatDialogStateStore.$reset();
      audioStore.$reset();

      appStateStore.setMiddleColumnSlot("chat");
      messageStore.getMessageList({ conversationId: conversation.id, createdAt: messageCreatedAt });
      messageStore.getPinMessage(conversation.id);
    },
    updateLocalSelectedConversation (data: { unreadCount?: number; lastMessageInfo?: IMessage }) {
      if (data.unreadCount !== undefined) {
        let unreadCount = data.unreadCount;

        if (unreadCount <= 0) {
          unreadCount = 0;
        }

        this.selectedConversation.unreadCount = unreadCount;

        if (this.selectedConversationIndex >= 0) {
          this.data.conversations.items[this.selectedConversationIndex].unreadCount = unreadCount;
        }
      }

      if (data.lastMessageInfo) {
        this.selectedConversation.lastMessageInfo = data.lastMessageInfo;
      }
    },
    updateLocalConversationList (conversation: Conversation, message?: IMessage) {
      const currentConversationIndex = this.data.conversations.items.findIndex((item) => item.id === conversation.id);

      if (currentConversationIndex === -1) {
        conversation.lastMessageInfo = message || conversation.lastMessageInfo;
        this.data.conversations.items.splice(this.unPinFirstIndex, 0, conversation);

        return;
      }

      // update conversation last message, unread count
      if (message) {
        this.data.conversations.items[currentConversationIndex].lastMessageInfo = message;
      }

      this.data.conversations.items[currentConversationIndex].unreadCount = conversation.unreadCount;
      // update conversation list

      if (currentConversationIndex === 0) return;

      /* update position */
      // xoa phan tu hien tai
      this.data.conversations.items.splice(currentConversationIndex, 1);
      // them phan tu vao vi tri chua pin
      this.data.conversations.items.splice(this.unPinFirstIndex, 0, conversation);
    },
  },
});
