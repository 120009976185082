import { IMessage, MessageAttachment } from "@domain/interfaces/message-interface";
import { defineStore } from "pinia";

import getBlobDuration from "get-blob-duration";
import RecordRTC from "recordrtc";

export const useAudioStateStore = defineStore("audio-state", {
  state: () => ({
    record: {
      visible: false,
      stream: undefined as MediaStream | undefined,
      recorder: undefined as RecordRTC | undefined,
      currentSecond: 0 as number,
      timer: undefined as any,
      currentIndex: -1 as number,
      player: undefined as HTMLAudioElement | undefined,
      recordOwner: "" as string,
      playerStatus: "pause" as "pause" | "playing",
      percentagePlayed: 0,
      recordTracks: [] as {
        player: HTMLAudioElement;
        audio: MessageAttachment;
        message: IMessage;
      }[],
    },
  }),

  getters: {
    isNextTrack: (state) => state.record.currentIndex + 1 < state.record.recordTracks.length,
    isPreviousTrack: (state) => state.record.currentIndex > 0,
  },
  actions: {
    async onStartRecord () {
      this.record.stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      this.record.recorder = new RecordRTC(this.record.stream, {
        type: "audio",
        mimeType: "audio/wav",
        recorderType: RecordRTC.StereoAudioRecorder, // force for all browsers
        numberOfAudioChannels: 2,
      });

      this.record.recorder.startRecording();
      this.record.timer = setInterval(() => {
        this.record.currentSecond = this.record.currentSecond + 1;
      }, 1000);
      this.record.visible = true;
    },
    async getRecordFile () {
      return new Promise((resolve, reject) => {
        this.record.recorder?.stopRecording(async () => {
          this.onStopRecording();
          const blob = this.record.recorder?.getBlob();

          if (blob) {
            resolve({
              blob,
              duration: blob ? await getBlobDuration(blob) : 0,
            });
          }
          reject(new Error("Record file error"));
        });
      }) as Promise<{ blob?: Blob; duration?: number }>;
    },
    async onStopRecording () {
      this.record.visible = false;
      this.record.currentSecond = 0;
      clearInterval(this.record.timer);
      this.record.timer = undefined;
      this.record.stream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    },
    async playRecordPlayer (player: HTMLAudioElement, recordOwner: string) {
      if (this.record.player && player !== this.record.player) {
        this.stopRecordPlayer();
      }
      this.record.player = player;
      this.record.currentIndex = this.record.recordTracks.findIndex((track) => track.player === this.record.player);
      this.record.recordOwner = recordOwner;
      this.record.playerStatus = "playing";
      await this.record.player.play();
    },
    async pauseRecordPlayer (player: HTMLAudioElement) {
      this.record.player = player;
      player.pause();
      this.record.playerStatus = "pause";
    },
    async onEndRecordPlayer () {
      this.record.playerStatus = "pause";
      this.record.percentagePlayed = 0;

      if (this.isNextTrack) {
        const nextTrack = this.record.recordTracks[this.record.currentIndex + 1];
        this.playRecordPlayer(nextTrack.player, nextTrack.message.createdByInfo.name);
      }
    },
    stopRecordPlayer () {
      if (this.record.player) {
        this.record.player.pause();
        this.record.player.currentTime = 0;
        this.record.playerStatus = "pause";
      }
    },
    closeRecordPlayer () {
      if (this.record.player) {
        this.stopRecordPlayer();
        this.record.player = undefined;
      }
    },
    addRecordTrack (data: { player: HTMLAudioElement; audio: MessageAttachment; message: IMessage }) {
      this.record.recordTracks = this.record.recordTracks
        .concat([data])
        .sort((a, b) => new Date(a.message.createdAt).getTime() - new Date(b.message.createdAt).getTime());
    },
    removeRecordTrack (message: { id?: string; requestId?: string }) {
      if (message.id) {
        if (this.record.player === this.record.recordTracks.find((track) => track.message.id === message.id)?.player) {
          this.closeRecordPlayer();
        }

        this.record.recordTracks = this.record.recordTracks.filter((track) => track.message.id !== message.id);
      }

      // xoa recordTrack theo requestId
      if (message.requestId) {
        this.record.recordTracks = this.record.recordTracks.filter((track) => track.message.requestId !== message.requestId);
      }
    },
    goToPreviousTrack () {
      if (this.isPreviousTrack) {
        const previousTrack = this.record.recordTracks[this.record.currentIndex - 1];
        this.playRecordPlayer(previousTrack.player, previousTrack.message.createdByInfo.name);
      }
    },

    goToNextTrack () {
      if (this.isNextTrack) {
        const nextTrack = this.record.recordTracks[this.record.currentIndex + 1];
        this.playRecordPlayer(nextTrack.player, nextTrack.message.createdByInfo.name);
      }
    },
  },
});
