import { getConversationNameFromUsers } from "@domain/helpers/conversation-helper";
import { getLastName } from "@domain/helpers/user-helper";
import { IMention, IMessage, MessageActions } from "@domain/interfaces/message-interface";
import { IUser } from "@domain/interfaces/user-interface";
import { defineStore } from "pinia";
import { useAccountStore } from "./account-store";
import { useAppStateStore } from "./app-store";

type RightSidebarSlot = "groupInfo" | "userInfo" | "setting" | undefined;

export const useChatDialogStateStore = defineStore("chat-dialog-state", {
  state: () => ({
    userContactModal: {
      visible: false,
      action: undefined as "addMember" | undefined,
    },
    contact: {
      selectedItems: [] as IUser[],
    },
    createGroup: {
      groupName: "",
      avatar: undefined,
      groupNameRef: "",
    },
    reactionMessageModalVisible: false,
    messageMenu: {
      selectedText: "",
      quoteText: "",
      activeMessage: {} as IMessage,
      selectedMessage: undefined as IMessage | undefined,
      messageAction: "" as MessageActions | undefined,
    },
    rightSidebar: {
      visible: false,
      slot: undefined as RightSidebarSlot,
    },
    mainInputState: {
      newContent: "" as string | undefined,
      height: 32,
      clipboardDataText: "",
      mention: [] as IMention[],
      content: "" as string,
      emojiVisible: false,
      placeholderVisible: true,
    },
    messageInputState: {
      selectedRange: undefined as Range | undefined,
    },
    upload: {
      media: {
        fileList: [] as File[],
        visible: false,
      },
      files: {
        fileList: [] as File[],
        visible: false,
      },
    },
    toolbar: {
      visible: false,
      isBold: false,
      isUnderline: false,
      isItalic: false,
      isStrikethrough: false,
      position: { x: 0, y: 0 },
    },
  }),
  actions: {
    openContactModal (action: "addMember") {
      this.userContactModal = { visible: true, action };
      this.contact.selectedItems = [];
    },
    closeContactModal () {
      this.userContactModal = { visible: false, action: undefined };
      this.contact.selectedItems = [];
    },
    closeMessageAction () {
      this.messageMenu.messageAction = undefined;
      this.messageMenu.selectedText = "";
      this.messageMenu.activeMessage = {} as IMessage;
      this.messageMenu.selectedMessage = undefined;
    },
    openMessageAction (action: MessageActions) {
      this.mainInputState.newContent = undefined;
      this.messageMenu.messageAction = action;
      this.messageMenu.selectedMessage = this.messageMenu.activeMessage;

      if (action === "edit") {
        this.mainInputState.newContent = this.messageMenu.activeMessage.content;
      } else {
        this.mainInputState.newContent = "";
      }

      if (action === "quote") {
        this.messageMenu.quoteText = this.messageMenu.selectedText;
      }
    },
    openReactionMessageModal () {
      this.reactionMessageModalVisible = true;
    },
    closeReactionMessageModal () {
      this.reactionMessageModalVisible = false;
    },
    openRightSidebar (slot: RightSidebarSlot) {
      this.rightSidebar.slot = slot;
      this.rightSidebar.visible = true;
    },
    closeRightSidebar () {
      this.rightSidebar.slot = undefined;
      this.rightSidebar.visible = false;
    },
    openCreateGroup () {
      useAppStateStore().setMiddleColumnSlot("createGroup");
      const ownerName = getLastName(useAccountStore().loginUser.name);
      this.createGroup.groupName = getConversationNameFromUsers(this.contact.selectedItems);
      this.createGroup.groupName = this.createGroup.groupName.trim().length > 0 ? `${ownerName}, ${this.createGroup.groupName}` : ownerName;

      setTimeout(() => {
        document.getElementById("input-group-name")?.focus();
      }, 200);
    },
    closeCreateGroup () {
      useAppStateStore().resetMiddleColumn();
      this.contact.selectedItems = [];
    },
    hideToolbar () {
      this.toolbar.visible = false;
      this.messageInputState.selectedRange = undefined;
      this.toolbar.isBold = false;
      this.toolbar.isItalic = false;
      this.toolbar.isUnderline = false;
      this.toolbar.isStrikethrough = false;
    },
    clearSelection () {
      const selection = window.getSelection();

      if (selection) {
        selection.removeAllRanges();
      }
      this.messageInputState.selectedRange = undefined;
    },
  },
});
