import "reflect-metadata";

import { Container } from "inversify";

import MessageRepository from "@domain/repositories/message-repository";
import MessageRepositoryApi from "@infrastructure/repositories/api/message-api-repository";

import AccountRepository from "@domain/repositories/account-repository";
import AccountRepositoryApi from "@infrastructure/repositories/api/account-api-repo";

import ConversationRepository from "@domain/repositories/conversation-repository";
import ConversationApiRepository from "@infrastructure/repositories/api/conversation-api-repository";

import MediaRepository from "@domain/repositories/media-repository";
import TheAdvanceMediaRepository from "@infrastructure/repositories/theadvance-media/media-repository";

import UserRepository from "@domain/repositories/user-repository";
import UserSocketRepository from "@infrastructure/repositories/api/user-socket-repo";

import FolderRepository from "@domain/repositories/folder-repository";
import FolderSocketRepository from "@infrastructure/repositories/api/folder-api-repository";

const repository = new Container();

if (process.env.VUE_APP_REPOSITORY === "api") {
  repository.bind<MessageRepository>(MessageRepository).to(MessageRepositoryApi);
  repository.bind<AccountRepository>(AccountRepository).to(AccountRepositoryApi);
  repository.bind<ConversationRepository>(ConversationRepository).to(ConversationApiRepository);
  repository.bind<MediaRepository>(MediaRepository).to(TheAdvanceMediaRepository);
  repository.bind<UserRepository>(UserRepository).to(UserSocketRepository);
  repository.bind<FolderRepository>(FolderRepository).to(FolderSocketRepository);
}

export default repository;
