import repository from "@config/repository";

import FolderRepository from "@domain/repositories/folder-repository";

import { IGetFolderConversationListQuery } from "@domain/interfaces/folder-interface";

export default function getFolderConversationListUseCase (query: IGetFolderConversationListQuery) {
  const folderRepository = repository.get(FolderRepository);

  return folderRepository.getFolderConversationList(query);
}
