import repository from "@config/repository";
import MediaRepository from "@domain/repositories/media-repository";
import MessageRepository from "@domain/repositories/message-repository";

import { getParseMode } from "@domain/helpers/message-helper";
import { CreateMessageBody, IMention, MessageActions, RootMessage } from "@domain/interfaces/message-interface";

export default async function createMessageUseCase (data: {
  conversationId: string;
  content: string;
  requestId: string;
  files?: File[];
  action?: MessageActions;
  rootMessage?: RootMessage;
  mention?: IMention[];
  quote?: { id: string; content: string };
  record?: { blob?: Blob; duration?: number };
}) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);
  const mediaRepository = repository.get<MediaRepository>(MediaRepository);

  const body: CreateMessageBody = {
    content: data.content,
    conversationId: data.conversationId,
    attachment: [],
    requestId: data.requestId,
    action: data.action,
    rootMessage: data.rootMessage,
    mention: data.mention,
    quote: data.quote,
    parseMode: getParseMode(data.content),
  };

  if (data.files) {
    for (let index = 0; index < data.files.length; index++) {
      const file = data.files[index];
      const result = await mediaRepository.uploadMedia(file);

      body.attachment.push({
        id: result.data.id,
        link: result.data.link,
        mimetype: file.type,
        size: file.size,
        originalname: file.name,
        thumbnail: result.data.thumbnail,
        duration: 0,
        height: result.data.height,
        width: result.data.width,
      });
    }
  }

  if (data.record && data.record.blob) {
    const result = await mediaRepository.uploadRecord(data.record.blob, data.record.duration);

    body.attachment.push({
      id: "",
      link: result.data.link,
      mimetype: data.record.blob.type,
      size: data.record.blob.size,
      originalname: result.data.filename,
      thumbnail: result.data.thumbnail,
      duration: result.data.duration,
      height: result.data.height,
      width: result.data.width,
    });
  }

  return messageRepository.create(body);
}
