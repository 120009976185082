import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { GetMessageDetailQuery } from "@domain/interfaces/message-interface";

export default async function getConversationDetailUseCase (query: GetMessageDetailQuery) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationRepository.getDetail(query);
}
