import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConfigStore } from "@presentation/store/config-store";
import { Socket } from "socket.io-client";

export default function onGetConfig (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("getConfig", (result) => {
    const configStore = useConfigStore();
    configStore.setToday(new Date(result.today));
  });
}
