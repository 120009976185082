import AccountRepository from "@domain/repositories/account-repository";
import ApiService from "@infrastructure/network/api";

import { IAccountLoginBody, IAccountLoginResponse } from "@domain/interfaces/account-interface";
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { connectLoginSocket, handleSocketConnectWithToken } from "@infrastructure/network/socket";
import { injectable } from "inversify";
import { Socket } from "socket.io-client";

@injectable()
export default class AccountApiRepository implements AccountRepository {
  private api = new ApiService();

  async login (data: IAccountLoginBody, sign: string): Promise<IAccountLoginResponse> {
    const result = await this.api.post("/app/user/login", data, {}, sign);

    if (result.code !== 1) throw new Error(result.message);

    return result;
  }

  connectAccountSocket (token: string): void {
    handleSocketConnectWithToken(token);
  }

  connectLoginSocket (): Socket<ServerToClientEvents, ClientToServerEvents> {
    return connectLoginSocket();
  }
}
