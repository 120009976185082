import createClientMessageUseCase from "@domain/use-cases/message/create-client-message";
import createMessageUseCase from "@domain/use-cases/message/create-message";
import editMessageUseCase from "@domain/use-cases/message/edit-message";
import getCountUnreadMessageUseCase from "@domain/use-cases/message/get-count-unread-message";
import getMessageListUseCase from "@domain/use-cases/message/get-message-list";
import getPinMessageUseCase from "@domain/use-cases/message/get-pin-message";
import pinMessageUseCase from "@domain/use-cases/message/pin-message";
import reactionMessageUseCase from "@domain/use-cases/message/reaction-message";
import removeMessageUseCase from "@domain/use-cases/message/remove-message";
import unpinMessageUseCase from "@domain/use-cases/message/unpin-message";

import Message from "@domain/entities/message-entity";

import { groupMessageByDate, scrollToEndConversation, scrollToMessage } from "@domain/helpers/message-helper";
import {
  GetMessageListQuery,
  IMention,
  IMessage,
  MessageActions,
  PinMessageBody,
  QuoteInfo,
  ReactionMessageBody,
  UnPinMessageBody,
} from "@domain/interfaces/message-interface";
import { defineStore } from "pinia";
import { useAccountStore } from "./account-store";
import { useAlertStore } from "./alert-store";
import { useAppStateStore } from "./app-store";
import { useAudioStateStore } from "./audio-store";
import { useConversationStore } from "./conversation-store";

export const useMessageStore = defineStore("message", {
  state: () => ({
    page: 1,
    fetching: false,
    data: [] as IMessage[],
    total: 0,
    limit: 10,
    newestMessageId: "",
    currentMessageId: "",
    searchQuery: <GetMessageListQuery>{},
    itemsByDate: [] as { date: string; messageInDay: IMessage[] }[],
    isBefore: false, // còn tin nhắn chưa load
    isNewest: false, // còn tin nhắn mới
    isMoreFetching: false,
    waypoint: {
      list: [] as IMessage[],
      lastSeenMessage: {} as IMessage,
      readCount: 0,
    },
    wheel: {
      up: false,
    },
    unreadCount: 0,
    highlightMessageId: "",
    pin: {
      list: [] as IMessage[],
      active: undefined as IMessage | undefined,
      loading: false,
    },
  }),
  getters: {},
  actions: {
    async getMessageList (query: GetMessageListQuery, highlightMessageId?: string) {
      const conversationStore = useConversationStore();
      const accountStore = useAccountStore();

      this.fetching = true;
      this.searchQuery = query;
      this.waypoint = {
        list: [],
        lastSeenMessage: {} as IMessage,
        readCount: 0,
      };
      this.data = [];
      this.itemsByDate = [];
      this.page = 1;
      this.isBefore = true;
      this.newestMessageId = "";
      this.wheel = { up: false };

      if (query.conversationId !== conversationStore.selectedConversation.id) {
        this.pin.list = [];
        this.pin.active = undefined;
      }
      const result = await getMessageListUseCase(query);

      if (result.code !== 1) {
        return;
      }

      if (query.createdAt || query.createdAtBefore) {
        this.isBefore = result.data.items?.length > 0;
      }

      this.data = result.data.items;
      this.itemsByDate = groupMessageByDate(this.data);

      this.newestMessageId = result.data?.newestMessageId;
      this.fetching = false;
      this.isNewest = this.data[this.data.length - 1]?.id ? this.data[this.data.length - 1]?.id === this.newestMessageId : false;

      this.waypoint.list = result.data.items.filter(
        (item) =>
          item.createdBy !== accountStore.loginUser.username &&
          item.conversationId === query.conversationId &&
          item.createdAt > conversationStore.selectedConversation?.conversationDetails.lastSeenAt
      );

      if (this.waypoint.list && this.waypoint.list.length > 0) {
        this.waypoint.lastSeenMessage = this.waypoint.list[0];
        this.highlightMessageId = this.waypoint.list[0].id;
      }

      if (highlightMessageId) {
        this.highlightMessageId = highlightMessageId;
      }

      setTimeout(() => {
        conversationStore.updateConversationDetails({
          lastSeenAt: this.waypoint.lastSeenMessage.createdAt,
          lastSeenMessageId: this.waypoint.lastSeenMessage.id,
          conversationId: query.conversationId,
          readMessageCount: this.waypoint.readCount,
        });
      }, 100);

      scrollToMessage(conversationStore.selectedConversation.conversationDetails?.lastSeenMessageId);
    },
    async getMoreMessageList (query: GetMessageListQuery, type: "before" | "after") {
      if (this.isMoreFetching) {
        return;
      }

      this.isMoreFetching = true;
      const result = await getMessageListUseCase({
        conversationId: query.conversationId,
        createdAtBefore: type === "before" ? this.data[0].createdAt : undefined,
        createdAtAfter: type === "after" ? this.data[this.data.length - 1]?.createdAt : undefined,
      });

      this.isMoreFetching = false;

      if (result.code !== 1) {
        return;
      }

      const accountStore = useAccountStore();
      const conversationStore = useConversationStore();

      const items = result.data.items;

      if (type === "before") {
        this.data = items.concat(this.data);
        this.isBefore = result.data.items?.length === result.data.limit;
      } else if (type === "after") {
        this.data = this.data.concat(items);
        this.isNewest = this.data.length > 0 ? this.data[this.data.length - 1].id === this.newestMessageId : false;
        this.waypoint.list = this.waypoint.list
          .concat(items)
          .filter(
            (item) =>
              item.createdBy !== accountStore.loginUser.username &&
              item.conversationId === query.conversationId &&
              item.createdAt > conversationStore.selectedConversation.conversationDetails.lastSeenAt
          );
      }
      this.page = this.page + 1;
      this.itemsByDate = groupMessageByDate(this.data);
      this.newestMessageId = result.data?.newestMessageId;
    },
    async createMessage (
      message: {
        content: string;
        files?: File[];
        mention?: IMention[];
        action?: MessageActions;
        rootMessage?: IMessage;
        quote?: QuoteInfo;
        record?: {
          blob?: Blob;
          duration?: number;
        };
      },
      conversationId?: string
    ) {
      const conversationStore = useConversationStore();
      const messageStore = useMessageStore();
      const audioStateStore = useAudioStateStore();

      const sentConversationId = conversationId || conversationStore.selectedConversation.id;

      // create client message
      const clientMessage = createClientMessageUseCase({
        requestId: new Date().getTime().toString(),
        content: message.content,
        conversationId: sentConversationId,
        files: message.files,
        action: message.action,
        rootMessage: message.rootMessage,
        mention: message.mention,
        quote: message.quote,
        record: message.record,
      });

      messageStore.updateLocalUnreadCount(messageStore.unreadCount - conversationStore.selectedConversation.unreadCount);
      conversationStore.updateLocalSelectedConversation({ unreadCount: 0 });
      conversationStore.updateLocalConversationList(conversationStore.selectedConversation, clientMessage);
      this.data = this.data.concat([clientMessage]);

      this.itemsByDate = groupMessageByDate(this.data);
      scrollToEndConversation();

      // send client message to server
      const result = await createMessageUseCase({
        content: message.content,
        conversationId: sentConversationId,
        files: message.files,
        requestId: clientMessage.requestId,
        action: message.action,
        rootMessage: message.rootMessage,
        mention: message.mention,
        quote: message.quote,
        record: message.record,
      });

      if (result.code === 1) {
        const newMessage = new Message(result.data.message);

        const updateMessageIndex = this.data.findIndex((item) => item.requestId === result.data.requestId);
        audioStateStore.removeRecordTrack({ requestId: result.data.requestId });
        this.data[updateMessageIndex] = newMessage;
        this.itemsByDate = groupMessageByDate(this.data);

        conversationStore.updateLocalConversationList(conversationStore.selectedConversation, newMessage);
        conversationStore.updateConversationDetails({
          conversationId: newMessage.conversationId,
          lastSeenMessageId: newMessage.id,
          lastSeenAt: newMessage.createdAt,
          readMessageCount: conversationStore.selectedConversation.unreadCount,
        });
      }
    },
    async removeMessage (id: string) {
      const result = await removeMessageUseCase(id);

      if (result?.code !== 1) {
        return;
      }
      const conversationStore = useConversationStore();
      const audioStateStore = useAudioStateStore();

      const index = this.data.findIndex((item) => item.id === id);
      this.data.splice(index, 1);
      this.itemsByDate = groupMessageByDate(this.data);
      audioStateStore.removeRecordTrack({ id });

      // console.log(conversationStore.selectedConversation.lastMessageInfo, id);

      if (conversationStore.selectedConversation.lastMessageInfo?.id === id) {
        // console.log(conversationStore.selectedConversation.lastMessageInfo.id, id);

        conversationStore.selectedConversation.lastMessageInfo = this.data[index - 1];
      }
    },
    async getUnreadMessageCount () {
      const { data } = await getCountUnreadMessageUseCase();
      this.unreadCount = data.internal;
    },
    async editMessage (id: string, values: { content: string; mention: IMention[] }) {
      const { data } = await editMessageUseCase(id, values);

      if (!data) return;

      const editMessageIndex = this.data.findIndex((item) => item.id === id);

      if (editMessageIndex !== -1) {
        this.data[editMessageIndex].content = data.message.content;
        const conversationStore = useConversationStore();

        if (conversationStore.selectedConversation.lastMessageInfo.id === data.message.id) {
          conversationStore.selectedConversation.lastMessageInfo.content = data.message.content;
        }
      }
    },
    async reactionMessage (data: ReactionMessageBody) {
      const result = await reactionMessageUseCase(data);

      if (result.code !== 1) return;
      this.updateLocalMessageReaction(result.data.message);
    },
    async getPinMessage (conversationId: string) {
      const result = await getPinMessageUseCase(conversationId);

      if (result.code !== 1) return;
      this.pin.list = result.data.messages?.items || [];

      if (this.pin.list.length > 0) {
        this.pin.active = this.pin.list[0];
      }
    },
    async pinMessage (body: PinMessageBody) {
      const result = await pinMessageUseCase(body);

      if (result.code !== 1) {
        useAlertStore().error(result.message);

        return;
      }

      this.pin.list.push(result.data.message);
      this.pin.active = result.data.message;
    },
    async unpinMessage (body: UnPinMessageBody) {
      const result = await unpinMessageUseCase(body);

      if (result.code !== 1) return;
      this.pin.list = this.pin.list.filter((item) => !body.messageIds.includes(item.id));

      if (this.pin.list.length === 0) {
        useAppStateStore().closePinMessage();
        this.pin.active = undefined;
      }

      if (this.pin.active?.id && body.messageIds.includes(this.pin.active.id)) {
        this.pin.active = this.pin.list[0];
      }
    },
    async unpinAllMessage () {
      this.pin.loading = true;

      const result = await unpinMessageUseCase({
        conversationId: useConversationStore().selectedConversation.id,
        messageIds: this.pin.list.map((item) => item.id),
      });
      this.pin.loading = false;

      if (result.code !== 1) {
        return useAlertStore().error(result.message);
      }
      useAppStateStore().closePinMessage();
      this.pin.list = [];
      this.pin.active = undefined;
    },
    // local
    async getNextPinMessage () {
      if (!this.pin.active?.id) return;

      const currentIndex = this.pin.list.findIndex((item) => item.id === this.pin.active?.id);
      document.getElementById(this.pin.active.id)?.setAttribute("class", "");

      if (currentIndex < this.pin.list.length - 1) {
        this.pin.active = this.pin.list[currentIndex + 1];
      } else {
        this.pin.active = this.pin.list[0];
      }

      if (!this.data.find((item) => item.id === this.pin.active?.id)) {
        await this.getMessageList({
          conversationId: this.pin.active.conversationId,
          createdAt: this.pin.active.createdAt,
        });
      }

      this.highlightMessageId = this.pin.active.id;
      document.getElementById(this.pin.active.id)?.setAttribute("style", "background: rgba(0, 0, 0, 0.03)");
      scrollToMessage(this.pin.active.id);
      setTimeout(() => {
        this.highlightMessageId = "";

        if (this.pin.active?.id) {
          document.getElementById(this.pin.active.id)?.setAttribute("style", "");
        }
      }, 1000);

      if (currentIndex > 1) {
        const element = document.getElementById(`pin#${this.pin.active.id}`);

        element?.scrollIntoView({ behavior: "smooth", inline: "nearest" });
      }
    },
    updateLocalSelectedConversationMessage (message: IMessage) {
      this.data = this.data.concat([message]);
      this.itemsByDate = groupMessageByDate(this.data);
      this.waypoint.list.push(message);
      this.waypoint.lastSeenMessage = message;
    },
    updateLocalUnreadCount (count: number) {
      this.unreadCount = count > 0 ? count : 0;
    },
    updateLocalMessageReaction (message: IMessage) {
      const messageIndex = this.data.findIndex((item) => item.id === message.id);

      if (messageIndex !== -1) {
        this.data[messageIndex].reactions = message.reactions;
      }
      const pinIndex = this.pin.list.findIndex((item) => item.id === message.id);

      if (pinIndex !== -1) {
        this.pin.list[pinIndex].reactions = message.reactions;
      }
    },
  },
});
